<template>
  <div class="navGramos" v-if="!$route.meta.hideNavigation">
    <b-navbar toggleable="lg" >
      <b-container>
        <b-navbar-brand href="/"><b-img :src="require('../assets/logo.png')"></b-img></b-navbar-brand>

        <b-nav>
          <b-nav-item to="/dashboard" exact exact-active-class="active">Dashboard</b-nav-item>
          <b-nav-item to="/gestion_usuarios" active-class="active">Gestión de usuarios</b-nav-item>
          <b-nav-item to="" v-on:click="logout">Salir</b-nav-item>
        </b-nav>
      </b-container>
    </b-navbar>
  </div>
</template>
<script>
import auth from "../logic/auth";
export default {
    data() {
        return {
            autorizado: true
        };
    },
    methods: {
        logout() {
            this.autorizado = false;
            auth.deleteUserLogged();
        }
    },
    mounted() {
        this.autorizado = false;
        this.accessToken = auth.getUserLogged();
        if (this.accessToken!='undefined' && typeof(this.accessToken)!='undefined') {
            this.autorizado = true;
        }
    }
}

</script>