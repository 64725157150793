import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue"
import VueEllipseProgress from 'vue-ellipse-progress'
import VueMomentjs from 'vue-moment'
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/gramos.scss'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueEllipseProgress);
Vue.use(VueMomentjs);
Vue.use(VueApexCharts);
Vue.config.productionTip = false;

Vue.component('apexchart', VueApexCharts)
Vue.component('downloadExcel', JsonExcel)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
