import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name:'login',
        meta: { hideNavigation: true },
        component: () => import('../components/Login')
    },
    {
        path:'/dashboard',
        name:'dashboard',
        component: () => import('../components/Dashboard')
    },
    {
        path:'/gestion_usuarios',
        name:'gestion_usuarios',
        component: () => import('../components/GestionDeUsuarios')
    },
    {
        path:'/gestion_usuarios/usuarios/:user_id',
        name:'usuarios',
        component: () => import('../components/DashboardUsuario')
    },
    /*{
        path:'/dashboard/indicadores_usuarios',
        name: 'indicadores_usuarios',
        component: () => import('../components/DashboardIndicadoresUsuarios')
    }*/
]

const router = new VueRouter({
    mode:'history',
    // base:'http://localhost:8080',
    routes
})

export default router