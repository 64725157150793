import axios from "axios";
import Cookies from 'js-cookie'

export default {
    setUserLogged(userLogged) {
        Cookies.set("accessToken", userLogged);
    },
    getUserLogged() {
        return Cookies.get("accessToken");
    },
    deleteUserLogged() {
        Cookies.remove('accessToken');
    },
    login(email, password) {
        const user = { "email": email, "password": password, "loginAdmin": 'true' };
        return axios.post(process.env.VUE_APP_GRAMOSAPIHOST + "authenticate", user);
    }
};